.experiences {
	position: relative;
	background: url(../../assets//Images/cool-background.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.experiences .overlay {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		135deg,
		rgba(248, 161, 99, 0.01),
		rgba(249, 159, 4, 0.06)
	);
	backdrop-filter: blur(5px);
	opacity: 0.9;
}

.experiences-wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 1rem;
}

.experiences-box {
	position: relative;
	background: linear-gradient(135deg, rgb(76 44 21 / 1%), rgb(244 164 28 / 6%));
	-webkit-backdrop-filter: blur(100px);
	backdrop-filter: blur(100px);
	box-shadow: 0 0px 100px 0 rgb(244 164 28 / 0%);
	border: 1px solid rgba(68, 49, 17, 0.113);
	padding: 20px 12px;
}
.experiences-box:hover {
	box-shadow: 0 0px 200px 0 rgb(244 164 28 / 0%);
	background: linear-gradient(
		135deg,
		rgb(76 44 21 / 4%),
		rgb(244 164 28 / 10%)
	);
}
.experiences-box svg.opacity-icon {
	transition: 1s;
	position: absolute;
	font-size: 50px;
	color: var(--yellow-01);
	left: 40px;
	bottom: 20px;
	z-index: -1;
	opacity: 0.2;
	-webkit-transition: 1s;
	-moz-transition: 1s;
	-ms-transition: 1s;
	-o-transition: 1s;
}

.experiences-box::after,
.experiences-box::before {
	position: absolute;
	content: "";
	height: 0px;
	width: 0px;
	transition: all 0.5s;
	opacity: 0;
	color: #f4a41c;
}

.experiences-box::after {
	left: 0;
	top: 0;
	border-left: 2px solid;
	border-top: 2px solid;
	border-radius: 12px 0 0 0;
	-webkit-border-radius: 12px 0 0 0;
	-moz-border-radius: 12px 0 0 0;
	-ms-border-radius: 12px 0 0 0;
	-o-border-radius: 12px 0 0 0;
}

.experiences-box::before {
	border-right: 2px solid;
	border-bottom: 2px solid;
	border-radius: 0 0 12px 0;
	-webkit-border-radius: 0 0 12px 0;
	-moz-border-radius: 0 0 12px 0;
	-ms-border-radius: 0 0 12px 0;
	-o-border-radius: 0 0 12px 0;
	right: 0;
	bottom: 0;
}

.experiences-box:hover::before,
.experiences-box:hover:after {
	width: 80px;
	height: 80px;
	opacity: 1;
}

@media (max-width: 768px) {
	.experiences-box::after {
		-webkit-border-radius: 6px 0 0 0;
		-moz-border-radius: 6px 0 0 0;
		-ms-border-radius: 6px 0 0 0;
		-o-border-radius: 6px 0 0 0;
		border-radius: 6px 0 0 0;
	}

	.experiences-box::before {
		border-radius: 0 0 6px 0;
		-webkit-border-radius: 0 0 6px 0;
		-moz-border-radius: 0 0 6px 0;
		-ms-border-radius: 0 0 6px 0;
		-o-border-radius: 0 0 6px 0;
	}
}

.experiences-section::after {
	top: 0;
	left: 0;
}

.experiences-section::before {
	bottom: 0;
	right: 0;
}
.experiences-section::before,
.experiences-section::after {
	display: flex;
	content: "";
	position: absolute;
	width: 45%;
	height: 12rem;
	background-image: radial-gradient(#4c2c15 2px, transparent 0);
	background-size: 20px 20px;
	background-position: 0 0;
	opacity: 0.03;
}
