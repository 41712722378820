@tailwind base;
@tailwind components;
@tailwind utilities;

/* google font */
@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Cairo:wght@200..1000&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

/*  create root variables */
:root {
	--brown-01: #4c2c15;
	--orang-01: #f4a41c;
	--orang-02: #f4ac1c;
	--orang-03: #fcb634;
	--yellow-01: #fcd482;
	--yellow-02: #cfae77;
	--gray-01: #9c9494;
	--gray-02: #77706e;
	--pearl-01: #ede7de;
}

* {
	font-family: "Tajawal", sans-serif;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}
.border-style {
	position: relative;
}
.border-style::after {
	position: absolute;
	content: "";
	right: 0;
	bottom: 0;
	width: 44%;
	height: 1px;
	border-radius: 100px;
	background-color: var(--yellow-01);
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}

/* Animations  */
.moveupdown-animation {
	animation: movetodown 5s infinite alternate;
	-webkit-animation: movetodown 5s infinite alternate;
}
.gas-can-icon {
	animation: movetodown 6s infinite alternate;
	-webkit-animation: movetodown 6s infinite alternate;
}

.rotate-animation {
	animation: rotateAnimation 5s infinite linear;
	-webkit-animation: rotateAnimation 5s infinite linear;
}

@keyframes movetodown {
	0% {
		transform: translateY(0px);
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		-o-transform: translateY(0px);
	}
	100% {
		transform: translateY(100px);
		-webkit-transform: translateY(100px);
		-moz-transform: translateY(100px);
		-ms-transform: translateY(100px);
		-o-transform: translateY(100px);
	}
}

@keyframes rotateAnimation {
	0% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	100% {
		transform: rotate(1turn);
		-webkit-transform: rotate(1turn);
		-moz-transform: rotate(1turn);
		-ms-transform: rotate(1turn);
		-o-transform: rotate(1turn);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
}

.custom-transition {
	transition: 0.2s;
	-webkit-transition: 0.2s;
	-moz-transition: 0.2s;
	-ms-transition: 0.2s;
	-o-transition: 0.2s;
}
