.features-slides {
	width: 100%;
}
.features-slide {
	transition: 1s;

	cursor: pointer;
	text-align: center;
	background: #fff;
	box-shadow: 0 0px 20px 0 rgba(244, 172, 28, 0.2);
	border: 1px solid rgba(244, 172, 28, 0.3);
	padding: 18px 12px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	-webkit-transition: 1s;
	-moz-transition: 1s;
	-ms-transition: 1s;
	-o-transition: 1s;
}

.features-slide:hover {
	box-shadow: 0 0px 24px 0 rgba(244, 172, 28, 0.2);
	border: 3px solid rgba(244, 172, 28, 0.5);
}

.our-princibles {
	position: relative;
}

.our-princibles::before {
	position: absolute;

	content: "";
	right: -9px;
	bottom: 0px;
	height: 100%;
	width: 4px;
	border-radius: 100px;
	background-color: var(--orang-01);
}
.features-img2,
.features-img1 {
	position: absolute;
	opacity: 0.1;
	z-index: -1;
}
.features-img2 {
	right: 0px;
	top: 0px;
	height: 100px;
}
.features-img1 {
	left: 12px;
	bottom: 19px;
}

@media screen and (max-width: 768px) {
	.features-img2 {
		height: auto;
	}
}
