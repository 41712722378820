.glass-effect {
	background: #fff;
	backdrop-filter: blur(20px);
	box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.3);
	border: 1px solid rgba(255, 255, 255, 0.18);
}
.page-links {
	position: relative;
}

.page-links::after {
	transition: 0.4s;
	-webkit-transition: 0.4s;
	-moz-transition: 0.4s;
	-ms-transition: 0.4s;
	-o-transition: 0.4s;
	position: absolute;
	content: "";
	right: 0;
	bottom: 0;
	width: 0%;
	height: 100%;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	z-index: -1;
}

.page-links:hover::after {
	background: linear-gradient(
		135deg,
		rgba(255, 255, 255, 0.1),
		rgba(255, 255, 255, 0)
	);
	backdrop-filter: blur(20px);
	box-shadow: 0 8px 32px 0 rgba(252, 212, 130, 0.37);
	border: 1px solid rgba(252, 212, 130, 0.18);
	width: 100%;
}

.about-us-btn {
	transition: 0.4s;
	-webkit-transition: 0.4s;
	-moz-transition: 0.4s;
	-ms-transition: 0.4s;
	-o-transition: 0.4s;
}

/* hero style */
.petro-bark {
	position: relative;
}
.petro-bark::before {
	position: absolute;
	content: "";
	bottom: 0;
	right: 0;
	height: 4px;
	width: 64%;
	background-color: var(--orang-02);
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}
.about-us-btn:hover {
	background: linear-gradient(
		135deg,
		rgba(255, 255, 255, 0.1),
		rgba(255, 255, 255, 0)
	);
	backdrop-filter: blur(20px);
	box-shadow: 0 8px 32px 0 rgba(252, 212, 130, 0.37);
	border: 1px solid rgba(252, 212, 130, 0.18);
}

/* mobile-menu style */
.mobile-menu {
	background: #fff;
	backdrop-filter: blur(20px);
	box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
	border: 1px solid rgba(255, 255, 255, 0.18);
	width: 0;
}
.open-menu {
	width: 100% !important;
}
