.services-section {
	height: 400px;
	position: relative;
	left: 0%;
	background-image: linear-gradient(120deg, #cfae77 0%, #4c2c15 100%);
	transform: skewy(-4deg);
	-webkit-transform: skewy(-4deg);
	-moz-transform: skewy(-4deg);
	-ms-transform: skewy(-4deg);
	-o-transform: skewy(-4deg);
	transform-origin: top left;
	margin-bottom: 100px;
}
.services-wrapper {
	position: relative;

	padding: 35px 12px;
	margin-bottom: 200px;
}
.services-box {
	position: absolute;
	width: 82%;
	height: 400px;
	right: 0px;
	padding: 10px;
	top: 200px;
	transform: translateX(-13%) skewy(4deg);
	-webkit-transform: translateX(-13%) skewy(4deg);
	-moz-transform: translateX(-13%) skewy(4deg);
	-ms-transform: translateX(-13%) skewy(4deg);
	-o-transform: translateX(-13%) skewy(4deg);
}
.services-text {
	width: 100%;
	left: 0;
	top: 76px;
	transform: translate(0%) skewy(4deg);
	-webkit-transform: translate(0%) skewy(4deg);
	-moz-transform: translate(0%) skewy(4deg);
	-ms-transform: translate(0%) skewy(4deg);
	-o-transform: translate(0%) skewy(4deg);
}
.services-slides {
	width: 100%;
	padding: 0 17px;
}
.services-box .swiper-wrapper .swiper-slide {
	width: 370px !important;
}
.services-slide {
	width: 100%;
	transition: 0.7s;
	background-color: #fff;
	background-image: url(../../assets/Images/serv-slide-bg.png);
	background-repeat: no-repeat;
	background-size: 100%;
	box-shadow: inset 0px 0px 2px 0px #cfae77db;
	overflow: hidden;
	padding: 26px 36px;
	z-index: 0;
	border-radius: 8px;
	-webkit-transition: 0.7s;
	-moz-transition: 0.7s;
	-ms-transition: 0.7s;
	-o-transition: 0.7s;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.services-slide .icon-box {
	background: #fff;
	border: 2px solid rgba(86, 90, 207, 0.1);
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	height: 90px;
	padding: 10px;
	margin-bottom: 16px;
	text-align: center;
	width: 90px;
}

.services-slide:hover .icon-box {
	border: 1px solid rgba(244, 172, 28, 0.5);
}
.services-slide:hover .icon-box img {
	animation: wobble-hor-bottom 0.8s both;
}

.features-slide:hover {
	box-shadow: 0 0px 24px 0 rgba(244, 172, 28, 0.2);
	border: 1px solid rgba(244, 172, 28, 0.5);
}

/*  arrow icons */
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
	left: var(--swiper-navigation-sides-offset, 0px);
	right: auto;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
	right: var(--swiper-navigation-sides-offset, 0px);
	left: auto;
}

.swiper-button-prev,
.swiper-button-next {
	transition: 0.8s;
	background-color: #ede7de;
	border-radius: 50%;
	color: #cfae77;
	width: 30px;
	height: 30px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	-webkit-transition: 0.8s;
	-moz-transition: 0.8s;
	-ms-transition: 0.8s;
	-o-transition: 0.8s;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
	background-color: #cfae77;
	border-radius: 1px;
	color: #ede7de;
	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	-ms-border-radius: 1px;
	-o-border-radius: 1px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
	font-size: 18px;
}

/*  Create Aniamtion */
@keyframes wobble-hor-bottom {
	0%,
	to {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}

	15% {
		-webkit-transform: translateX(-8px) rotate(-3deg);
		transform: translateX(-8px) rotate(-3deg);
		-moz-transform: translateX(-8px) rotate(-3deg);
		-ms-transform: translateX(-8px) rotate(-3deg);
		-o-transform: translateX(-8px) rotate(-3deg);
	}

	30% {
		-webkit-transform: translateX(8px) rotate(3deg);
		transform: translateX(8px) rotate(3deg);
		-moz-transform: translateX(8px) rotate(3deg);
		-ms-transform: translateX(8px) rotate(3deg);
		-o-transform: translateX(8px) rotate(3deg);
	}

	45% {
		-webkit-transform: translateX(-8px) rotate(-2.6deg);
		transform: translateX(-8px) rotate(-2.6deg);
		-moz-transform: translateX(-8px) rotate(-2.6deg);
		-ms-transform: translateX(-8px) rotate(-2.6deg);
		-o-transform: translateX(-8px) rotate(-2.6deg);
	}

	60% {
		-webkit-transform: translateX(5px) rotate(1.4deg);
		transform: translateX(5px) rotate(1.4deg);
		-moz-transform: translateX(5px) rotate(1.4deg);
		-ms-transform: translateX(5px) rotate(1.4deg);
		-o-transform: translateX(5px) rotate(1.4deg);
	}

	75% {
		-webkit-transform: translateX(-3px) rotate(-1deg);
		transform: translateX(-3px) rotate(-1deg);
		-moz-transform: translateX(-3px) rotate(-1deg);
		-ms-transform: translateX(-3px) rotate(-1deg);
		-o-transform: translateX(-3px) rotate(-1deg);
	}
}

@media (max-width: 1400px) {
	.services-box {
		width: 86%;
		transform: translateX(-9%) skewy(4deg);
		-webkit-transform: translateX(-9%) skewy(4deg);
		-moz-transform: translateX(-9%) skewy(4deg);
		-ms-transform: translateX(-9%) skewy(4deg);
		-o-transform: translateX(-9%) skewy(4deg);
	}
}

@media (max-width: 1200px) {
	.services-box {
		width: 100%;
		transform: translateX(0%) skewy(4deg);
		-webkit-transform: translateX(0%) skewy(4deg);
		-moz-transform: translateX(0%) skewy(4deg);
		-ms-transform: translateX(0%) skewy(4deg);
		-o-transform: translateX(0%) skewy(4deg);
	}
}
@media (max-width: 992px) {
	.services-box {
		width: 82%;
		transform: translateX(-12%) skewy(4deg);
		-webkit-transform: translateX(-12%) skewy(4deg);
		-moz-transform: translateX(-12%) skewy(4deg);
		-ms-transform: translateX(-12%) skewy(4deg);
		-o-transform: translateX(-12%) skewy(4deg);
	}
}
@media (max-width: 768px) {
	.services-wrapper {
		margin-bottom: 80px;
	}
	.services-slide {
		padding: 16px 26px;
	}

	.services-slides {
		padding: 0 11px;
	}
	.services-box {
		width: 100%;
		height: 400px;
		padding: 0;
		bottom: -220px;
		transform: translateX(0%) skewy(4deg);
		-webkit-transform: translateX(0%) skewy(4deg);
		-moz-transform: translateX(0%) skewy(4deg);
		-ms-transform: translateX(0%) skewy(4deg);
		-o-transform: translateX(0%) skewy(4deg);
	}

	.services-box .swiper-wrapper .swiper-slide {
		width: 355px !important;
	}

	.services-slide .icon-box {
		width: 75px;
		height: 75px;
		padding: 8px;
		margin-bottom: 14px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		width: 26px;
		height: 26px;
	}
	.swiper-button-prev:after,
	.swiper-button-next:after {
		font-size: 14px;
	}
}

@media (max-width: 768px) {
	.services-wrapper {
		margin-bottom: 80px;
	}
	.services-slide {
		padding: 16px 26px;
	}

	.services-slides {
		padding: 0 11px;
	}
	.services-box {
		width: 100%;
		height: 400px;
		padding: 0;
		bottom: -220px;
		transform: translateX(0%) skewy(4deg);
		-webkit-transform: translateX(0%) skewy(4deg);
		-moz-transform: translateX(0%) skewy(4deg);
		-ms-transform: translateX(0%) skewy(4deg);
		-o-transform: translateX(0%) skewy(4deg);
	}

	.services-box .swiper-wrapper .swiper-slide {
		width: 100% !important;
	}
}
