.footer {
	position: relative;
	background: url(../../assets//Images/cool-background.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
}

.footer .overlay {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		135deg,
		rgba(248, 161, 99, 0.01),
		rgba(249, 159, 4, 0.06)
	);
	backdrop-filter: blur(5px);
	opacity: 0.9;
}

.footer-img2,
.footer-img3 {
	position: absolute;
	opacity: 0.1;
	z-index: -1;
}
.footer-img2 {
	right: -30px;
	top: -5px;
}
.footer-img3 {
	left: 12px;
	bottom: 19px;
}

@media screen and (max-width: 768px) {
	.footer-img2 {
		right: 243px;
	}
}
